
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Form, Message } from 'view-design'
import { regExp } from '@/utils/validator'
import { CommonService } from '@/api/common'
import { ClinicAPI } from '@/api'
import moment from 'moment'
import { isMobilePhone, isIDCard } from '@hxq/utils'
import { isEmpty } from '../../utils/util'

@Component
export default class Patient extends Vue {
  @Prop({ default: 'edit' })
  private action!: string
  @Prop({ default: 0 })
  private patientId!: number
  private relationList: any[] = [{ code: '2', value: '本人' }]
  private seeVisable = false
  private editCardNumVisable = false
  private editMobileVisable = false
  private formData = {
    name: '',
    sex: 1,
    date: '',
    userRelation: '', // 关系
    cardNum: '',
    mobile: ''
  }
  private doctorId = ''
  public $refs!: {
    form: Form
  }
  private relationCols: any[] = []
  private from = ''
  @Watch('action', {immediate: true, deep: true })
  @Watch('patientId', { deep: true })
  private actionChange (v: number) {
    if (this.action === 'edit' || this.action === 'see') {
      this.seeVisable = true
      this.getPatient()
    }
    if (this.action === 'add') {
      this.seeVisable = false
      this.reset()
    }
  }
  public async created () {
    this.doctorId = this.$route.query.id as string
    this.from = this.$route.query.from as string
    CommonService.getDic('userRelation').then(async (res) => {
      this.relationCols = res.result
    })
  }
  private getPatient () {
    ClinicAPI.getPatient(this.patientId + '').then((res) => {
      this.formData = {
        ...res.result,
        date: moment(res.result.birthday).format('YYYY-MM-DD')
      }
      this.editCardNumVisable = this.formData.cardNum ? true : false
      this.editMobileVisable = this.formData.mobile ? true : false
    })
  }
  public reset () {
    this.formData = {
      name: '',
      sex: 1,
      date: '',
      userRelation: '', // 关系
      cardNum: '',
      mobile: ''
    }
  }
  private onBirthConfirm (value: any) {
    this.formData.date = moment(value).format('YYYY-MM-DD')
  }
  private validate () {
    return new Promise((resolve, reject) => {
      if (!this.formData.name.length) {
        reject('请输入您的姓名')
      }
      if (!this.formData.date) {
        reject('请选择出生年月')
      }
      if (!this.formData.userRelation) {
        reject('请选择你与患者的关系')
      }
      if (!isIDCard(this.formData.cardNum)) {
        reject('请输入正确的身份证号码')
      }
      if (!isMobilePhone(this.formData.mobile)) {
        reject('请输入正确的手机号码')
      }
      resolve()
    })
  }
  public submit () {
    return this.validate().then((res) => {
      const data = {
        ...this.formData,
        sex: this.formData.sex + ''
      }
      if (this.action === 'add') {
        return ClinicAPI.addPatient(data)
      } else {
        return ClinicAPI.modPatient(data)
      }
    }).catch((err) => {
      return (Message as any).error(err)
    })
  }
}
