
import { Vue, Component } from 'vue-property-decorator'
import Patient from './Patient.vue'
import { ClinicAPI } from '@/api'
import moment from 'moment'
import { OssUpload } from '@/components'
import { CommonService } from '@/api/common'
import QRCode from 'qrcode'

@Component({
  components: {
    Patient,
    OssUpload
  }
})
export default class Pay extends Vue {
  public $refs!: {
    patient: Patient,
    content: any
  }
  private doctorId = ''
  private patientList = []
  private imagesList: any[] = []
  private selectPatient: any = ''
  private payMoney = 0
  private payQrCode = ''
  private modalPayTitle = ''
  private formData = {
    payType: 'alipay',
    doctorId: '',
    patientId: '',
    patientName: '',
    patientMobile: '',
    patientCardNum: '',
    remark: '',
    caseImages: '',
    time: ''
  }
  private modalVisible = false
  private modalPayVisible = false
  private modalTitle = '添加就诊人'
  private patientAction = 'add'
  private selectPatientId = ''
  private clinicJson: any = {}
  private payDuration = ''
  private payTime = ''
  private orderNo = 0
  private durationArr: any[] = []
  private mounted () {
    this.doctorId = this.$route.query.id as string
    this.clinicJson = this.$store.getters.getClinicJson
    this.payMoney = this.clinicJson.timeList.length * +this.$route.query.price
    this.payDuration = this.clinicJson.timeList.map((item: any) => {
      this.durationArr.push(item.codeStr)
      return item.duration
    }).reduce((num: number, item: any) => {
      return num + item
    })
    const hour = this.durationArr.toString().split('-')
    this.payTime = this.clinicJson &&
      moment(this.clinicJson.date).format('YYYY年MM月DD日') + ' ' + hour[0] + '-' + hour.pop()
    this.getPatientList()
  }
  private selectPatienters (item: any) {
    if (!item.cardNum || !item.mobile) {
      this.modalVisible = true
      this.patientAction = 'edit'
      this.modalTitle = '完善就诊人'
      this.selectPatientId = item.id + ''
    }
    this.selectPatient = item
  }
  private patientClick (type: string, item?: any) {
    this.modalVisible = true
    this.patientAction = type
    if (type === 'add') {
      this.modalTitle = '添加就诊人'
    }
    if (type === 'see') {
      this.modalTitle = '查看就诊人'
      this.selectPatientId = item.id + ''
    }
  }
  private onOk (type: string) {
    if (type === 'add' || type === 'edit') {
      this.$refs.patient.submit().then((res) => {
        if (res.result) {
          this.modalVisible = false
          this.getPatientList()
        }
      })
    } else {
      this.modalVisible = false
    }

  }
  private onCancel () {
    this.$refs.patient.reset()
    this.modalVisible = false
  }
  private handleSubmit () {
    console.log(this.selectPatient)
    if (this.patientList.length === 0) {
      return this.$Message.error('请添加就诊人')
    }
    if (!this.selectPatient.cardNum || !this.selectPatient.mobile) {
      return this.$Message.error('请完善就诊人')
    }
    if (this.formData.remark === '') {
      return this.$Message.error('症状描述不能为空！')
    }
    this.formData = {
      ...this.formData,
      doctorId: this.doctorId,
      patientId: this.selectPatient.id,
      patientName: this.selectPatient.name,
      patientMobile: this.selectPatient.mobile,
      patientCardNum: this.selectPatient.cardNum,
      caseImages: this.imagesList ? this.imagesList.join(',') : '',
      time: this.clinicJson
    }
    console.log(this.formData)
    ClinicAPI.createOrder(this.formData).then((res) => {
      if (res.result.errCode === 1001) {
        this.$Message.error(`${res.result.errMsg}`)
      }
      let qrcodeUrl = ''
      this.orderNo = res.result.orderNo
      if (this.formData.payType === 'alipay') {
        this.modalTitle = '支付宝支付'
        qrcodeUrl = JSON.parse(res.result.orderString).alipay_trade_precreate_response.qr_code
        this.qrcode(qrcodeUrl)
      } else {
        this.modalTitle = '微信支付'
        const packageValue = res.result.woi.packageValue
        qrcodeUrl = packageValue.substring(packageValue.indexOf('=') + 1, packageValue.length)
        this.qrcode(qrcodeUrl)
      }
    })
  }
  private qrcode (qrcodeUrl: string) {
    QRCode.toDataURL(qrcodeUrl)
      .then((url) => {
        this.modalVisible = true
        this.payQrCode = url
      })
      .catch((err) => {
        console.error(err)
      })
  }
  private paySuccess () {
    ClinicAPI.getOrder(this.orderNo).then((res) => {
      if (res.result.status === 2) {
        this.$router.push('/hxqClinic/paySuccess')
      } else {
        return this.$Message.error(`该订单${res.result.statusStr}，请重新支付`)
      }
    })
  }
  private changeImages (images: string[]) {
    this.imagesList = images
  }
  private getPatientList () {
    ClinicAPI.getPatientList(this.doctorId).then((res) => {
      this.patientList = res.result
      this.selectPatient = res.result[0]
    })
  }
}
